<template>
  <div class="normal-text bg-dark-theme text-center">
    <div class="bg-1">
      <div class="d-block m-0">
        <LandingPageHeader :trasparentHeader="true" />
        <div class="clearfix"></div>
      </div>
      <div class="h-md-8vh"></div>
      <section class="landing_view">
        <div class="row">
          <div class="col-12 col-md-8 mx-md-auto">
            <div class="row">
              <div class="col-12">
                <h1 class="large-text fnt-tny">MOM AI- ASK ANYTHING</h1>
                <h2 class="large-text-2 px-1 px-md-3">
                  AI Chat, AI Image generator, AI writer, AI Voiceover, AI Transcribe, AI Writer and more      
                </h2>
                <p>
                  In the digital era, staying ahead means embracing innovative technologies. 
                  MOM App is your gateway to this transformation, offering AI-driven solutions 
                  that propel your business into the future powered by OpenAI, GPT4, DALLE2, DALLE3, Azure Whisper, & Stable Diffusion.
                </p>
              </div>
              <div class="h-3vh"></div>
              <div class="col-12 d-flex justify-content-center">
                <b-link class="custom-design custom-design-black px-md-5 font-weight-bold tra-black-bg" :to="userRedirect">ENTER THE WORLD OF AI</b-link>
              </div>
                <div class="h-3vh"></div>
              <div class="col-12 d-flex justify-content-center">
                <b-link href="https://play.google.com/store/apps/details?id=com.momitnow.twa" target="_blank">
                  <b-img :src="require('@/assets/images/landing/google-play-store.png')" class="gplay_img" fluid />
                </b-link>
              </div>
              <div class="h-md-90vh"></div>
              <div class="col-12 d-none d-md-block">
                <p>
                  Revolutionize your business with MOM AI, the all-in-one AI solution. 
                  Our content generator creates compelling copy in an instant, 
                  enhancing your marketing and communication strategies. 
                  The image generator brings visual ideas to life, perfect for eye-catching graphics. 
                  Accelerate development with our code generator, streamlining software projects. 
                  Text-to-speech offers engaging audio content, while speech-to-text transforms 
                  spoken words into accurate text, boosting productivity. 
                  The AI chat is your personal assistant. Embrace MOM AI for a seamless, 
                  innovative approach to business growth.
                </p>
              </div>
              <div class="h-md-10vh"></div>
            </div>
          </div>
        </div>
      </section>
    </div>


    <section class="landing_section text-center">
      <div class="row">
        <div class="col-12">
          <h1 class="large-text-2 fnt-tny text-light text-center text-uppercase">MOM AI: Your One-Stop AI-Powered Content Solution</h1>
        </div>
        <div class="col-12">
          <p>
            Transform Your 
            Content Creation: Struggling with stale content? MOM AI is here to 
            revolutionize your approach with AI-driven creativity.   
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-11 mx-md-auto">
          <div class="row">
            <div class="col-12 col-md-4" v-for="(widget, wk) in landingWidgets" :key="`widget_${wk}`">
              <div class="ai_widget_img">
                <b-img :src="require(`@/assets/images/landing/ai/${widget.img}`)" class="home_img" fluid />
              </div>
              <div class="ai_widget_label custom-design mx-auto text-uppercase">
                <span>{{ wk+1 }}. {{ widget.title }}</span>
              </div>
              <div class="ai_widget_text mx-auto px-3">
                <p v-for="(p, wkp) in widget.text" :key="`widgetp_${wkp}_${wk}`">
                  {{ p }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>


    <section class="landing_section text-center">
      <div class="row">
        <div class="col-12">
          <h1 class="large-text-2 fnt-tny text-center text-uppercase">
            Empower Your Business with Our Versatile Templates
          </h1>
        </div>
      </div>
      <div class="h-md-3vh"></div>
      <div class="row">
        <div class="col-12 col-md-4 px-md-5 px-3 mb-2 mb-md-0">
          <b-img :src="require('@/assets/images/landing/Website/MOM AI.webp')" fluid class="border-rounded-20" />
        </div>
        <div class="col-12 col-md-8">
          <div class="row">
            <div class="col-12 px-md-3">
              <p>
                Unlock the potential of your business with our comprehensive range of templates designed to enhance your digital presence. Our expansive selection not only caters to various needs but also provides a robust foundation for your content strategy.
              </p>
            </div>
            <div class="col-12">
              <ul class="no-list-style text-light">
                <li>Blog Content</li>
                <li>Email Template</li>
                <li>Social Media</li>
                <li>Video Content</li>
                <li>Website Content</li>
                <li>Fun & Quote</li>
                <li>Medium Content</li>
                <li>Tik Tok</li>
                <li>Instagram</li>
                <li>Success Story</li>
              </ul>
            </div>
            <div class="col-12">
              <b-link class="custom-design px-md-5 font-weight-bold" :to="userRedirect">ENTER THE WORLD OF AI</b-link>
            </div>
          </div>
        </div>
      </div>
    </section>


    <section class="landing_section text-center">
      <div class="row">
        <div class="col-12">
          <h2 class="large-text-2 fnt-tny text-uppercase">
            Whenever you need something just ask MOM AI
          </h2>
        </div>
      </div>

      <div class="row landing_grids">
        <div class="col-12 col-md-4 grid_items" v-for="(menu, index) in servicesList" :key="`l_card_${index}`">
          <div class="ai_widget_img">
            <b-img fluid :src="require(`@/assets/images/landing/ai/${menu.image}`)" class="home_img" />
          </div>
          <div class="ai_widget_label custom-design mx-auto">
            <span>{{ $t(menu.title) }}</span>
          </div>
        </div>
      </div>
    </section>


    <section class="landing_section text-center">
      <div class="row">
        <div class="col-12">
          <h1 class="large-text-2 fnt-tny text-uppercase text-center text-light">
            Pricing
          </h1>
          <p>
            Free forever, No credit card required
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-11 col-md-12 mx-auto">
          <SubscriptionList 
            :subscriptions="userSubscriptions"
            @upgradeSubscription="upgradeSubscription"
            :upgradeBtn="`Subscribe`"
          />
        </div>
      </div>
    </section>
    <LandingPageFooter :trasparentFooter="true" />
    <AppFooterBlack :trasparentFooter="true" />
  </div>
</template>

<script>
import LandingPageFooter from '@core/layouts/components/LandingPageFooter.vue'
import AppFooterBlack from '@core/layouts/components/AppFooterBlack.vue'
import LandingPageHeader from '@core/layouts/components/LandingPageHeader.vue'
import LandingSVG from '@/views/LandingSVG.vue'
import SubscriptionList from '@/views/shared/subscription/SubscriptionList.vue'
import { mapGetters, mapActions } from 'vuex'
import { isEmpty } from '@/utilities'
const servicesList = [
  {image: 'ai-chat.webp', title: 'ai chat'},
  {image: 'ai-image-generator.webp', title: 'ai image generator'},
  {image: 'ai-code.webp', title: 'ai code generator'},
  {image: 'ai-text-to-speech.webp', title: 'ai text to speech'},
  {image: 'ai-speech-to-text.webp', title: 'ai speech to text'},
  {image: 'ai-writer.webp', title: 'ai writer'},
]

const landingWidgets = [
  {
    img: '1.select-template.webp',
    title: 'select template',
    text: [
      'Choose from our diverse range of templates to find the perfect starting point for your content.'
    ]
  },
  {
    img: '2.write-prompt.webp',
    title: 'write your prompt',
    text: [
      'Provide a brief and specific prompt to guide the content generation according to your unique requirements.'
    ]
  },
  {
    img: '3.generate-content.webp',
    title: 'generate content',
    text: [
      'Effortlessly create tailored content with our AI-powered tool, delivering high-quality results in seconds.'
    ]
  },
]

export default {
  name: "UserLandingPage",
  components: {
    LandingSVG,
    LandingPageFooter,
    AppFooterBlack,
    LandingPageHeader,
    SubscriptionList
  },
  data: () => ({
    servicesList,
    landingWidgets
  }),
  directives: {
    lazyBackground: {
      bind: (el, binding) => {
        el.style.backgroundImage = `url('${binding.value}')`;
      }
    }
  },
  computed: {
    ...mapGetters('subscriptionPlan', [
        'isSubscriptionLoaded',
        'userSubscriptions',
        'isSubscriptionCountryLoaded',
        'countries',
    ]),
    userRedirect: {
      get(){
        if(window.SuperApp.getters._isUser()){
          return {name: 'user-dashboard'}
        }
        return {name: 'apps-login'}
      }
    },
    shopRedirect: {
      get(){
        if (window.SuperApp.getters._isStore()){
          return { name: 'shop-dashboard' }
        }
        return {name: 'apps-shop-login'}
      }
    },
    providerRedirect: {
      get(){
        if (window.SuperApp.getters._isProvider() && !window.SuperApp.getters._isAgent()){
          return { name: 'provider-requests'}
        }
        else if (window.SuperApp.getters._isProvider() && window.SuperApp.getters._isAgent()){
          return { name: 'provider-agent-dashboard' }
        }
        return {name: 'apps-provider-login'}
      }
    },
  },
  methods: {
    ...mapActions('subscriptionPlan', [
        'getSubscriptionPackages',
        'getCountries',
    ]),
    goToService(url) {
      if (url) {
        this.$router.push({
          path: url,
        })
      }
    },
    upgradeSubscription(subscription){
      this.$router.push({
        name: 'apps-registration'
      })
    }
  },
  async mounted(){
    if (!localStorage.getItem('userSelectedCountryId')) {
      try {
        const _ipInfo = await fetch('https://api.ipregistry.co/?key=dsnrdu52t7ii07ny')
        const ipInfo = await _ipInfo.json()
        if(!isEmpty(ipInfo?.location)){
          if(!this.isSubscriptionCountryLoaded){
            await this.getCountries().then(data => {
              if (data._statusCode === 200) {
                  const countries = data.responseData
                  const isCountryExist = countries.find(x => x.country_name == ipInfo.location.country.name)
                  if (isCountryExist) {
                    localStorage.setItem('userSelectedCountryId', isCountryExist.id)
                  }
              }
            })
          }else{
            const countries = this.countries
            const isCountryExist = countries.find(x => x.country_name === ipInfo.location.country.name)
            if (isCountryExist) {
              localStorage.setItem('userSelectedCountryId', isCountryExist.id)
            }
          }
        }
      } catch (error) {
        console.log(error)
      }
    }
    if (!this.isSubscriptionLoaded) {
        await this.getSubscriptionPackages()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/landing-scoped.scss';

.bg-1 {
  background-image: url('~@/assets/images/landing/ai/ai-landing-main.webp');
  background-position: center center; /* Centers the image */
  background-repeat: no-repeat; /* Prevents repeating the image */
  background-size: cover; /* Ensures the image covers the entire element */
  padding-bottom: 1.5rem;
}
</style>

<style lang="scss">
@import '~@core/scss/base/landing.scss';
@import '~@core/scss/base/landing-page.scss';
</style>
